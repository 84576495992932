.dropdown-container {
    position: relative;
    display: inline-flex;
    width: 100%;
    max-width: 250px;

    @media only screen and (min-width: 576px) {
        min-width: 250px;
    }

    &.full-width {
        max-width: none;
    }

    .dropdown-text {
        width: 100%;
        padding: 0.4rem 0.8rem;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border: 1px solid rgba(39, 39, 59, 0.5);
        &:hover {
            border: 1px solid var(--meteorite);
        }
        background-color: transparent;
        h5 {
            text-align: center;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            font-size: large;
            line-height: normal;
            letter-spacing: 0.5px;
            @media only screen and (max-width: 576px) {
                font-size: medium;
            }

            &.full-width {
                max-width: 70vw;
            }

            &.left {
                text-align: left;
            }

            &.right {
                text-align: right;
            }
        }
    }
    .dropdown-input {
        .dropdown-active {
            border: 1px solid var(--meteorite);
            background-color: rgba(255, 255, 255, 1);
        }

        &.full-width {
            width: 100vw;
        }

        min-width: 250px;
        display: flex;
        justify-content: center;
        h5 {
            flex-grow: 1;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }

    .dropdown-button {
        background-color: var(--meteorite);
        aspect-ratio: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        .dropdown-icon {
            color: var(--white);
        }
    }

    .dropdown {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        background-color: var(--white);
        z-index: 99;
        max-height: 300px;
        overflow: auto;
        border-radius: 0 0 8px 8px;
        // filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1)); /* This is causing visibility issues in Safari */
        border: 1px solid rgba(0, 0, 0, 0.15);
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                color: var(--onyx);
                padding: 0.3rem 0.7rem;
                display: flex;
                gap: 3%;
                align-items: center;

                &:hover {
                    color: var(--white);
                    background-color: var(--meteorite);
                    cursor: pointer;
                }
            }

            .dropdown-divider {
                color: var(--onyx);
                font-family: "Noto Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.5px;
                background-color: rgba(0, 0, 0, 0.05);
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: rgba(0, 0, 0, 0.1);
            }

            .header-divider {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-top-width: 0px;
            }
        }
    }
}
