.bonus-earned-modal{
    .modal-content {
        width: 100%;
        max-width: 320px;
        padding: 0 1.2rem;
        .bonus-guess-icon{
            display: flex;
            justify-content: center;
            img {
                width: 80px;
                height: auto;
            };
        }
        
        h5 {
            line-height: 35px;
        }

        p {
            color: #666666;
        }
    }
}