.activity-feed-section {
    background-color: var(--ghost-white);
    border-top: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
    .activity-feed-container {
        width: 100%;
        margin: auto;
        color: var(--gunmetal);
       
        .truncate-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            max-width: 150px;
        }
    
        .acitvity-feed-body {
           
            padding: .9rem 0rem;
    
            .score-title {
                font-size: 18px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
    
            .score-text {
                color: var(--pacific-blue);
            }
    
            .score-subtitle {
                font-size: 12px;
                color: var(--gunmetal);
                text-overflow: ellipsis;
                white-space: nowrap;
            }
         
            .top-score-card {
                border: 1px solid #D7C7C7; 
                display: flex;
                align-items: center;
                gap: .7rem;
                padding: 0.6rem 1rem;
                margin-bottom: .5rem;
                border-radius: 0.7rem;
                position: relative;
                width: 100%;

                @media screen and (min-width: 576px) {
                    width: 70%;
                    margin: auto;
                }
    
                .top-score-info {
                   overflow: hidden;
                   width: 100%;     
                   position: relative;
                }
            }
    
            .score-card {
                width: 90%;
                background-color: #ffff;
                position: relative;

                .top-score-badge{
                    position: absolute;
                    top: -20%;
                    left: 3%;
                    padding: 0.15rem 0.45rem;
                    border-radius: 0.3rem;
                    background-color: var(--light-mustard);
                    display: flex;
                    justify-content: center;
                    align-items: center;
 

                    span {
                        color: #000;
                        opacity: 0.6;
                        font-size: 10px;
                    }
    
                }

                @media screen and (min-width: 576px) {
                    width: 60%;
                }


                border: 1px solid #D7C7C7; 
                padding: 0.6rem 1rem;
                border-radius: 0.7rem;
                height: auto;
            }
    
            .scores-container {
                position: relative;
                .scores-feed {
                    display: flex;
                    gap: .5rem;
                }
               
            }
        }
         
    }    
}