.warning-modal {
    color: var(--emperor-blue);
    button {
        width: 100%;
        max-width: 200px;
        background-color: var(--tree-green);
        border: 1px solid var(--emperor-blue);
        font-weight: bold;
        border-radius: 10px;
    }
}
