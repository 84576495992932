@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins";

.credits {
    max-width: 600px;
    h1 {
        font-size: 21px;
        color: var(--onyx);
    }

    h3 {
        margin-top: 1.5rem;
        font-size: 21px;
    }
}