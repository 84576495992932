.about-priceme-section {
    .about-priceme-container {
        .app-text-title {
            font-family: 'Noto Sans';
            font-weight: bold;
        }

        .nested-list {
            list-style-type: lower-alpha;
            margin-left: 1rem;
        }
    }
}