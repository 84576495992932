.challenges-history-container {
    min-height: 100vh;

    .user-challenges-dropdown {
        margin: 10px 0px 30px;
    }

    .challenges-history-item-container {
        position: relative;
        min-height: 66px;
        border: 1px solid var(--gunmetal);
        border-radius: 15px;
        padding: 15px;

        .ch-item-date {
            position: absolute;
            top: -10px;
            background-color: var(--light-mustard);
            font-size: 10px;
            font-weight: bold;
            height: 19px;
            padding: 2px 15px;
            border-radius: 5px;

            &.sent {
                background-color: var(--meteorite);
                color: white;
            }
        }

        .property-location {
            font-size: 14px;
            font-weight: bold;
        }

        .property-location {
            font-size: 14px;
            font-weight: bold;
        }

        .item-players {
            font-size: 12px;
            color: var(--gunmetal);
            font-style: italic;
        }
    }

    .no-challenge-history-text {
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        margin-top: 15px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .send-challenge {
            font-weight: bold;
            color: #1597bb;
            cursor: pointer;
        }
    }
}
