.overlay {
    inset: 0;
    background: #000;
    height: 100%;
    width: 100%;
}

.cta-container {
    position: fixed;
    width: 100%;
    background: var(--white);
    z-index: 100;
    transition: all;
    transition-duration: 0.5s;
    border-radius: 1rem 1rem 0 0;
    display: block;
    bottom: 0;
    padding-bottom: 1.5rem;
    left: 0;

    @media only screen and (min-width: 576px) {
        display: none;
    }

    .header {
        text-align: center;
        width: 100%;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        padding-top: 3rem;
        margin-bottom: 1rem;
        position: relative;
        
        h5 {
            font-size: 21px;
        }

        button {
            all: unset;
            position: absolute;
            right: 3.5%;
            top: 16%;
        }
    }

    .body {
        text-align: center;
        padding: 0 .5rem;
        font-size: 15px;    
        max-width: 400px;
        margin: 0 auto;
        color: #666666;

        .remind-me-btn {
            padding: 0.8rem 2rem;
        }

        .instruction {
            padding: 0 1rem;
            margin: .5rem auto 1rem auto;
        }

        .steps-container {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            padding: 1rem 0;

            .step-item {
                flex-grow: 1;
                flex-basis: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                line-height: 1.2;
                flex-direction: column;

                p {
                    width: 100%;
                    max-width: 100px;
                }

                .add-btn {
                    border: 2px solid var(--emperor-blue);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 8px;
                    margin: 0 auto;
                }

                .finish-btn {
                    color: #007AFD;
                    font-weight: 700;
                    background: #F2F1F6;
                    margin: 0 auto;
                    padding: .2rem .5rem;
                    border-radius: .5rem;
                }

                .share-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 45px;
                    .content {
                        position: relative;
                        .ios-pointer {
                            position: absolute;
                            bottom: -5px;
                            right: 2px;
                        }
                        .android-pointer {
                            position: absolute;
                            bottom: -7px;
                            right: -7px;
                        }
                    }
                }

                .add-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 45px;
                    .content {
                        position: relative;
                        .ios-pointer {
                            position: absolute;
                            bottom: -12px;
                            right: -7px;
                        }
                        .android-pointer {
                            position: absolute;
                            bottom: -7px;
                            right: -12px;
                        }
                    }
                }

                .finish-icon {
                    display: flex;
                    justify-content: center;
                    height: 45px;
                    align-items: center;
                    .content {
                        position: relative;
                        
                        .pointer {
                            position: absolute;
                            bottom: -15px;
                            right: -12px;
                        }
                    }
                }

            }
        }

    }

}
