@import "../../../../node_modules/bootstrap/scss/_mixins";

footer {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 10vh;
    font-size: x-small;

}

a {
    color: inherit;
}
