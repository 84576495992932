.start-screen-challenger-results-modal {
    .close-icons {
        float: right;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        .close {
            cursor: pointer;
        }
    }

    .modal-content {
        width: 100%;
        max-width: 320px;

        .modal-footer {
            padding-bottom: 3rem;
        }

        .swiper-pagination {
            position: static;

            margin: 0.7rem 0;

            .swiper-pagination-bullet {
                height: 10px;
                width: 10px;
            }
            .swiper-pagination-bullet-active {
                background: var(--deep-saffron);
            }
        }

        a {
            &:hover {
                cursor: pointer;
            }
        }

        .modal-body {
            p {
                font-family: Noto Sans;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.5px;
                text-align: center;
            }
        }
    }

    .start-screen-challenger-results-modal-slide-group {
        h5 {
            font-weight: bold;
            font-family: "Merriweather";
        }

        p {
            color: #666666;
            font-family: "Noto Sans";
        }

        h2 {
            text-align: center;
        }

        .slide-img-wrapper {
            display: flex;
            justify-content: center;
            margin: 1.5rem 0;
        }
    }

    .sscrm-button {
        width: 80%;
    }

    .see-history-text {
        font-size: 16px;
        color: var(--meteorite);
        text-align: center;
        cursor: pointer;
    }
}
