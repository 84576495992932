.custom-tooltip {
    background-color: #ffffff !important;
    border: 1px solid rgba(108, 38, 196, 0.3);
    color: #6C26C4 !important;
    width: 100%;
    max-width: 234px;
    border-radius: 0.7rem;
    font-weight: 600;
    text-align: center;
    -webkit-box-shadow: 2px 7px 15px -7px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 7px 15px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 7px 15px -7px rgba(0, 0, 0, 0.2);
    z-index: 20;
    opacity: 1 !important;

    --rt-transition-show-delay: 0.5s !important;
    --rt-transition-closing-delay: 0.5s !important;

    .content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .content-texts {
            flex: 1; /* Take remaining space */
            text-align: center; 
        }

        .close-btn {
            all: unset;
            position: absolute;
            top: -6px;
            right: 0;
            opacity: 1;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .react-tooltip-arrow {
        border-right: 1px solid rgba(108, 38, 196, 0.3);
        border-bottom: 1px solid rgba(108, 38, 196, 0.3);
    }
}