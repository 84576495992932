@import "../../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

$indicator-color: var(--purple-heart);
$indicator-border-color: var(--purple-heart);

$current-indicator-color: var(--purple-heart);
$current-indicator-border-color: var(--meteorite);

.scroll-arrow {
    margin-bottom: 3.2px;
    z-index: 20;

    &:hover {
        cursor: pointer;
    }
}

.progress-indicators {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;

    &.scrollable {
        position: relative;
        z-index: 20;
        overflow-x: auto;
        user-select: none;
        padding: 5px;

        &:hover {
            cursor: grab;
        }
    }

    &.grabbing {
        cursor: grabbing;
    }

    .current {
        border: 3px solid $current-indicator-border-color;
        border-radius: 50%;
    }

    .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 1;
        font-weight: 700;

        .step-missed {
            position: absolute;
            top: 18%;
            left: 18%;
            z-index: 10;
            color: red;
        }

        .practice-day {
            position: absolute;
            top: -3.5%;
            z-index: 10;
            color: white;

            & + .step-icon {
                color: transparent;
            }
        }

        .step-total-points {
            position: absolute;
            top: 110%;
            font-size: 12px;
            font-weight: 300;
            opacity: 0.6;
            font-family: "Noto Sans";
        }

        .step-icon {
            border-radius: 50%;
            background: #c2c2c2;
            font-size: 10px;
            text-align: center;
            color: var(--white);
            position: relative;
            height: 20px;
            width: 20px;
            font-size: 12px;
            line-height: 20px;

            &.active {
                background: $indicator-color;
                border: 1px solid $indicator-border-color;
            }

            &.scrollable:hover {
                cursor: grab;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &.current {
            border-radius: 50%;

            .step-icon {
                &.active {
                    color: var(--white);
                    background-color: $current-indicator-color;
                }

                &.scrollable:hover {
                    cursor: grab !important;
                }

                &:hover {
                    cursor: default !important;
                }
            }
        }
    }

    .indicator-line {
        width: 100%;
        height: 3px;
        background: #c2c2c2;
        flex: 1;
        min-width: 24px;

        &.active {
            background: $indicator-color;
        }
    }

    &#days {
        .step {
            .step-icon {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .step p {
        font-size: 11px;
        bottom: -20px;
    }
}

.progress-indicators::-webkit-scrollbar {
    display: none;
}
