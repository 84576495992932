.challenged-modal {
    .close-icons {
        float: right;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        .close {
            cursor: pointer;
            display: none;
        }
    }

    &.guest-modal {
        .close-icons {
            padding: 15px;
        }
    }

    .modal-content {
        width: 100%;
        display: flex;
        justify-content: center;

        .modal-footer {
            padding-bottom: 3rem;
        }

        a {
            &:hover {
                cursor: pointer;
            }
        }

        .modal-body {
            flex: 0 1 auto;
            padding: 0 2.4rem !important;

            p {
                font-family: Noto Sans;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
            }
        }
    }

    .challenged-modal-body {
        .app-text-title {
            margin-top: 5% !important;
            font-size: calc(1.29rem + 0.9vw) !important; // this is the style for "fs-2"
        }

        p {
            color: #666666;
        }

        .slide-img-wrapper {
            display: flex;
            justify-content: center;
            margin: 1.5rem 0 !important;

            .house-sold-icon {
                max-width: 167px;
            }
        }

        .sold-date-text {
            margin-top: -15px;
        }

        .property-info-text {
            margin-top: -10px;
        }
    }
}
