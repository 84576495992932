.toggle-switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28.5px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: var(--grey);
  border: 3px solid var(--grey);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 0;
  top: 1px;
  width: 20px;
  height: 20px;
  background-color: var(--white);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #13BE43;
  border: 3px solid #13BE43;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(28px);
  background-color: var(--white);
}
