@mixin common-guess-price-styles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 12px;
    border: 1px solid var(--nobel-gray);
    color: var(--purple-heart);
    position: relative;
    z-index: 1;
    margin: 0;
}

#guess-price-container {
    position: relative;

    #guess-price-tooltip {
        padding: 0.7rem;
        background-color: #ffffff !important;
        border: 1px solid rgba(108, 38, 196, 0.3);
        color: var(--purple-heart);
        width: 100%;
        max-width: 180px;
        border-radius: 0.7rem;
        font-weight: 600;
        text-align: center;
        -webkit-box-shadow: 2px 7px 15px -7px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 2px 7px 15px -7px rgba(0, 0, 0, 0.2);
        box-shadow: 2px 7px 15px -7px rgba(0, 0, 0, 0.2);

        --rt-transition-show-delay: 0.5s !important;
        --rt-transition-closing-delay: 0.5s !important;

        .content {
            position: relative;
            z-index: 2;

            .close-btn {
                all: unset;
                position: absolute;
                top: -6px;
                right: -5px;
                opacity: 1;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .react-tooltip-arrow {
            border-right: 1px solid rgba(108, 38, 196, 0.3);
            border-bottom: 1px solid rgba(108, 38, 196, 0.3);
        }
    }

    #guess-price {
        @include common-guess-price-styles;
        background-color: #ffffff;
        padding: 0.5rem 1rem;

        &::placeholder {
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.5px;
            color: var(--nobel-gray);
        }

        &.gp-input-updating {
            padding-right: 2rem; // for the x (clear input) button
        }
    }

    #guess-price-input-disabled {
        @include common-guess-price-styles;
        background-color: lightgray;
        padding: 1.1rem 1rem;

        &.m-has-guessed-all {
            padding: 0.4rem 1rem;
        }

        div {
            cursor: not-allowed;
            overflow-x: auto;

            // Hide scrollbar for webkit browsers (Chrome, Safari)
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .user-guess {
            margin-right: 0.25rem;
            text-decoration: line-through;
            color: var(--nobel-gray);
        }

        .actual-price {
            font-style: italic;
            font-weight: bolder;
            color: var(--purple-heart);
        }

        .guess-score {
            top: 0;
            right: 11px;
            font-size: 12px;
            font-weight: normal;
            color: var(--purple-heart);
        }
    }
}
