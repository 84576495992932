.modal-container-finished {
    .close-icons {
        float: right;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        .close {
            cursor: pointer;
        }
    }
    .modal-content {
        width: 100%;
        max-width: 320px;
        text-align: center;
        .modal-feedback {
            margin: 0px 0px 10px 0px;
            font-size: 20px;
        }
        .guess {
            margin-top: 20px;
            line-height: 20%;

            p:first-child {
                font-size: 12px;
                color: #666666;
                font-weight: 400;
            }

            p:nth-child(2) {
                font-size: 18px;
                font-weight: 600;
            }
        }

        .points {
            margin-top: 20px;
            font-size: 25px;
            color: #6c26c4;
        }
        .time-left {
            font-size: 12px;
            color: #666666;
            padding-bottom: 10px;
        }
        .register-button {
            width: 80%;
            align-self: center;
            margin-bottom: 10px;
        }
        .share-buttons {
            width: 80%;
            align-self: center;
            margin-bottom: 10px;
        }
    }
}
