.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .loading-text {
        font-size: 20px;
        padding: 20px;
    }

    .progressbar-container {
        position: relative;
        .center-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
