.modal-container-reminder {
    .modal-content {
        width: 100%;
        max-width: 320px;
        text-align: center;
        padding: 10px;

        .x-btn {
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-weight: bolder;
            stroke-width: 1px;
            stroke: var(--midnight);
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }

        .rank-icon {
            width: 117px;
            height: 117px;
            align-self: center;
        }

        .register-button {
            width: 60%;
            align-self: center;
            margin: 10px;
        }

        .close-btn {
            font-weight: 500;
            line-height: 22px;
            text-decoration: none;
            color: #6c26c4;
        }

        .modal-headers {
            font-family: "Merriweather";
            font-size: 21px;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 0.5px;
            text-align: center;
            color: var(--onyx);
        }

        .modal-body {
            font-family: "Noto Sans";
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.5px;
            text-align: center;
            color: #666666;
        }
    }
}
