.guest_hint_container {
    color: var(--purple-heart);
    display: flex;
    align-items: center;
    gap: 3px;
    position: absolute;
    
    left: 0;

    top: -12%;

    @media screen and (min-width: 350px) {
        top: -18%;
    }

    h6 {
        font-size: 13px;
        margin: 0;
    }
}