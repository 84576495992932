.modal-container-result {
    .close-icon-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .header-text {
            flex: 1; /* Take remaining space */
            text-align: center; 
            font-size: 21px;
            font-weight: bold;
            font-family: "Merriweather";
        }

        .close {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 5px;
        }
    }
    .modal-body {
        padding-top: 0
    }
    .modal-content {
        width: 100%;
        max-width: 320px;
        text-align: center;
        .modal-feedback {
            margin: 0px 0px 10px 0px;
            font-size: 20px;
        }
        .guess {
            margin-top: 35px;
            line-height: 55%;

            p:first-child {
                font-size: 16px;
                color: #666666;
                font-weight: 400;
            }

            p:nth-child(2) {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 25px;
            }
        }

        .points {
            margin: 30px 0;
            font-size: 32px;
            color: #6c26c4;
            font-weight: 600;
        }

        .results-button {
            width: 75%;
            align-self: center;
            margin-bottom: 20px;
        }
        .has-tooltip {
            text-decoration: none;
        }
        .react-tooltip {
            background: whitesmoke !important;
            color: black !important;
            max-width: 95svw;
            z-index: 1;
        }
    }
}
