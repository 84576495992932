.modal-container-challenge-result {
    .close-icon-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .header-text {
            flex: 1; /* Take remaining space */
            text-align: center;
            font-size: 21px;
            font-weight: bold;
            font-family: "Merriweather";
        }

        .close {
            cursor: pointer;
            position: absolute;
            right: 10px; /* Adjust as needed */
        }
    }
    .modal-body {
        padding-top: 0;
    }
    .modal-content {
        width: 100%;
        max-width: 500px;
        text-align: center;
        .modal-feedback {
            margin: -1rem 0px 10px 0px;
            font-size: 21px;
        }

        .price {
            margin-top: 20px;
            line-height: 40%;

            p {
                letter-spacing: 0.5px;

                &:first-child {
                    color: #666666;
                    font-weight: 400;
                }

                &:nth-child(2) {
                    font-weight: 600;
                    margin-bottom: 20px;
                }
            }
        }

        .versus {
            margin-top: 20px;
            display: flex;
            align-items: center;
            position: relative;

            p:first-child {
                color: #666666;
                font-weight: 400;
                position: absolute;
                top: 80%;
                right: 0;
            }
        }

        .score {
            margin-top: 10px;
            max-width: 40%;
            line-height: 95%;
            position: relative;

            p {
                letter-spacing: 0.5px;

                &:first-child {
                    color: #666666;
                    font-weight: 400;
                    word-break: break-word;
                    line-height: 1.3;
                }

                &:nth-child(2) {
                    font-weight: 600;
                    margin-bottom: 20px;
                    position: absolute;
                    top: 100%;
                    left: 15%;
                }
            }
        }

        .winner-text {
            font-size: 16px;
            margin: 20px 0 5px;
            font-family: "Noto Sans";
            color: #666666;
        }
        .scorer {
            font-size: 40px;
            color: #6c26c4;
            font-weight: 600;
            line-height: 100%;
        }
        .modal-button {
            width: 75%;
            align-self: center;
            margin: 20px 0 10px;
            font-size: 23px;
            letter-spacing: 0.5px;
            padding: 1rem 0.5rem;
        }
    }

    .see-history-text {
        font-size: 16px;
        color: var(--meteorite);
        text-align: center;
        cursor: pointer;
    }
}
