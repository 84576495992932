.app-button {
    all: unset;
    border-radius: 500px;
    padding: 0.8rem 1.5rem;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
    }
}

.gold-solid {
    background-color: var(--deep-saffron);
    color: var(--onyx);

    &:hover {
        background-color: #ed8d1a !important;
    }
}

.purple-solid {
    background-color: var(--meteorite);
    color: var(--white);

    &:hover {
        background-color: #361263 !important;
    }
}

.light-purple-solid {
    background-color: var(--purple-heart);
    color: var(--white);

    &:hover {
        background-color: #361263 !important;
    }
}

.red-solid {
    background-color: #f62929;
    color: white;
}

.green-solid {
    background-color: #32c12f;
    color: black;
}

.unbordered-purple-solid {
    color: var(--meteorite);
}

.unbordered-purple-heart-solid {
    color: var(--purple-heart);

    &:hover {
        background-color: var(--white) !important;
    }
}

.unbordered-gold-solid {
    color: var(--deep-saffron);
}

.gold-bordered {
    border: 1px solid var(--deep-saffron);
    color: var(--deep-saffron);

    &:hover {
        border: 1px solid #ed8d1a !important;
        color: #ed8d1a;
        background-color: transparent !important;
    }
}

.purple-bordered {
    border: 1px solid var(--meteorite);
    color: var(--meteorite);

    &:hover {
        border: 1px solid #361263 !important;
        color: #361263;
        background-color: transparent !important;
    }
}

.purple-outlined {
    border: 1px solid var(--meteorite);
    color: var(--meteorite);
    background-color: transparent !important;

    &:hover {
        border: 1px solid #361263 !important;
        color: #361263;
    }
}

.white-outlined {
    border: 1px solid white;
    color: white;
    background-color: transparent !important;
    transition: all 0.3s ease;

    &:hover {
        border: 1px solid #6a1b9a !important;
        color: #6a1b9a;
        background-color: #f3e5f5;
    }
}

.disabled {
    opacity: 0.6;
}
