.date-picker-container {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 250px;
    @media only screen and (min-width: 576px) {
        min-width: 250px;
    }
    .date-picker-wrapper {
        width: 100%;
        input {
            width: 100%;
            padding: 0.23rem 0.8rem;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border: 1px solid var(--meteorite);
            font-size: large;
            @media only screen and (max-width: 576px) {
                font-size: medium;
                padding: 0.25rem 0.8rem;
            }
        }
    }

    .date-picker-popper {
        z-index: 3;
        width: 100%;

        @media only screen and (min-width: 426px) {
            left: 17px !important;
        }

        .react-datepicker {
            width: 100%;

            &__navigation { 
                top: 20px;
            }

            &__current-month,
            &__day-name {
                color: var(--onyx);
            }

            &__month-container {
                width: 100%;
            }

            &__day {
                &:hover {
                    background-color: #eee5f9;
                }

                &--keyboard-selected {
                    background-color: #eee5f9;
                }

                &--selected {
                    background-color: var(--purple-heart);

                    &:hover {
                        background-color: var(--meteorite);
                    }
                }
            }
        }
    }

    .dropdown-text {
        width: 100%;
        padding: 0.4rem 0.8rem;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border: 1px solid rgba(39, 39, 59, 0.2);
        margin: 0;
        &:hover {
            border: 1px solid var(--meteorite);
        }
        background-color: rgba(39, 39, 59, 0.03);
        &:hover {
            background-color: rgba(255, 255, 255, 1);
        }
        h5 {
            text-align: center;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            font-size: large;
            line-height: normal;
            letter-spacing: 0.5px;
            @media only screen and (max-width: 576px) {
                font-size: medium;
            }
        }
    }

    .dropdown-button {
        background-color: var(--meteorite);
        aspect-ratio: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        .dropdown-icon {
            color: var(--white);
        }

        &:hover {
            cursor: pointer;
        }
    }
}
