.custom-input {
    position: relative;
    
    input {
        width: 100%;
        padding: 0.5rem 0.5rem;
        font-size: 13px;
        border: 1px solid var(--nobel-gray);
        border-radius: 0.5rem;
        background-color: rgba(52, 52, 98, 0.05);
        transition: all 0.15s;
        color: var(--onyx);

        &:hover {
            background-color: white;
        }
        &:focus,
        &:active {
            outline: 1px solid var(--emperor-blue);
            border: 1px solid var(--emperor-blue);
            background-color: white;
        }

        &::placeholder {
            font-family: "Noto Sans";
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.5px;
            color: var(--nobel-gray);
        }
    }

    .clear-guess-btn {
        all: unset;
        position: absolute;
        right: 5%;
        top: calc(50% - 18px / 2);
        height: 18px;
        width: 18px;
        background: #c2c2c2;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffff;

        z-index: 2;

        &:hover {
            cursor: pointer;
            background: #b9b9b9;
        }
    }
}
