$table-height: 430px;
$award-counter-badge-dimension: 22px;
$trophy-icon-size: 3.25rem;
$default-icon-size: 2.5rem;
$dropdown-max-width: 350px;
$left-right-paddings: 1.25rem;
$middle-padding: 0.6rem 0.25rem 0 0.25rem;

.container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;

    // Note: We need to put :global here so that the CSS modules can read the nesting of SCSS
    // https://github.com/vercel/next.js/discussions/19417#discussioncomment-381306
    :global {
        .tab-buttons,
        .leaderboard-scope-buttons {
            display: flex;
            justify-content: center;
            width: 100%;

            button {
                width: 100%;
                font-size: 16px;
                color: var(--onyx);
                text-align: left;
                letter-spacing: 0.5px;
            }

            &.tab-buttons {
                #challenges-tooltip {
                    font-size: 10px;
                    top: 80px;
                    right: 10px;
                    left: unset;
                    max-width: 150px;
                    letter-spacing: 0.1px;
                    line-height: normal;

                    .content {
                        padding: 0;

                        .close-btn {
                            border-bottom: none;
                            top: -20px;
                            right: -10px;

                            svg {
                                width: 13px;
                                height: 13px;
                            }
                        }
                    }

                    .react-tooltip-arrow {
                        bottom: -5px;
                        left: 50%;
                        right: 50%;
                    }
                }

                button {
                    background-color: transparent !important;
                    padding: 0;
                    font-weight: 500;
                    border-bottom: 2px solid var(--grey);
                    line-height: 40px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    &.active {
                        font-weight: 800;
                        background-color: transparent !important;
                        color: var(--purple-heart);
                        border-bottom: 2px solid var(--purple-heart);
                    }
                }
            }

            &.leaderboard-scope-buttons {
                button {
                    font-weight: 400;
                    background-color: var(--white);
                    border: 1px solid var(--nobel-gray);
                    border-radius: 8px;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    &:first-of-type {
                        border-right: 0.5px solid var(--grey);
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &:last-of-type {
                        border-left: 0.5px solid var(--grey);
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }

                    &.active {
                        font-weight: 800;
                        background-color: #eee5f9;
                    }
                }
            }
        }

        .invite-button-mobile {
            text-decoration: none;
        }

        .leaderboard-title,
        .profile-stats-title {
            color: var(--onyx);
            font-family: "Merriweather";
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px; /* 190.476% */
            letter-spacing: 0.5px;
        }

        .dropdown-row {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .dropdown-container {
                &.dropdown-container {
                    max-width: 60%;
                }

                .dropdown-input {
                    min-width: 100%;

                    .dropdown-text {
                        max-width: 80%;
                        min-width: 80%;
                    }
                }

                .dropdown {
                    border-radius: 8px;

                    li {
                        &:not(.dropdown-divider) {
                            margin-top: 0.25rem;
                            margin-bottom: 0.25rem;

                            &:hover {
                                background-color: #e2d5f3 !important;
                                color: var(--onyx);
                            }
                        }

                        &.dropdown-divider {
                            &:hover {
                                background-color: rgba(0, 0, 0, 0.05) !important;
                                color: var(--onyx);
                                cursor: auto;
                            }
                        }

                        .item-star {
                            display: flex !important;
                        }
                    }
                }
            }

            .dropdown-container,
            .date-picker-container {
                h5 {
                    max-width: 65svw;

                    @media screen and (min-width: 375px) {
                        max-width: 70svw;
                    }

                    @media screen and (min-width: 425px) {
                        max-width: 75svw;
                    }
                }
            }

            &.my-stats-row {
                margin: 0 auto;
                @media screen and (min-width: 425px) {
                    max-width: $dropdown-max-width;
                }
            }

            .date-picker-container {
                max-width: 100%;

                &:hover {
                    cursor: pointer;
                }

                .dropdown-button {
                    padding: 0.35rem 0.57rem;
                }

                .dropdown-text {
                    padding: 0.35rem 0.57rem;

                    &:hover {
                        border-right: 0;
                    }
                }
            }
        }

        .leaderboard-table {
            width: 100%;
            height: 400px;
            overflow-y: auto;

            table {
                border-collapse: collapse;
                width: 100%;

                tbody {
                    tr {
                        height: 25%;
                    }
                }

                thead,
                tfoot {
                    th {
                        position: sticky;
                        z-index: 1;
                        background: var(--white);
                    }
                }

                thead th {
                    top: -0.5px;
                    padding: 0;

                    &:first-of-type {
                        .header-text {
                            padding-right: $left-right-paddings;
                        }
                    }

                    &:last-of-type {
                        .header-text {
                            padding-left: $left-right-paddings;
                        }
                    }

                    .header-text {
                        padding: $middle-padding;
                        opacity: 0.6;
                        color: var(--gunmetal);
                        text-align: center;
                        font-family: "Noto Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    .header-border-bottom {
                        border-bottom: 1px solid var(--grey);
                        padding-bottom: 4px;
                        display: block;
                        width: inherit;
                    }
                }

                tfoot th {
                    bottom: 0;
                    color: var(--onyx);
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.5px;

                    &:first-of-type {
                        padding-right: $left-right-paddings;
                    }

                    &:last-of-type {
                        padding-left: $left-right-paddings;
                    }
                }

                th,
                td {
                    text-align: center;
                    padding: $middle-padding;

                    &:first-of-type {
                        text-align: center;
                    }

                    &:last-of-type {
                        text-align: center;
                    }
                }

                td {
                    &:first-of-type {
                        padding-right: $left-right-paddings;
                    }

                    &:last-of-type {
                        padding-left: $left-right-paddings;
                    }
                }

                th {
                    font-size: larger;
                    font-weight: 700;
                }

                td:has(> .invite-friends-card) {
                    padding: 0;
                    padding-top: 10px;

                    .invite-friends-card {
                        width: 90%;
                        position: sticky;
                        margin: 0 auto;
                        border-radius: 10px;
                        background: var(--white);
                        font-size: 1rem;
                        padding: 5px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
                        z-index: 3;

                        &:hover {
                            cursor: pointer;
                        }

                        .card-text {
                            margin: 0;
                        }
                    }
                }

                .empty-ranking {
                    opacity: 0;
                }
            }
        }

        .sticky {
            z-index: 9;
            background-color: var(--white);
        }

        .cta-buttons {
            row-gap: 0.75rem;

            .play-again-button,
            #share-button {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.3rem;
            }

            .play-again-button {
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
                background-color: var(--gold);
                color: var(--midnight);
                font-weight: 700;
                font-size: x-large;
                border: 1px solid var(--midnight);

                &:hover {
                    opacity: 0.95;
                    cursor: pointer;
                    background-color: var(--gold);
                }
            }

            .play-again-button {
                background-color: var(--tree-green);
                color: var(--white);

                &:hover {
                    opacity: 0.95;
                    background-color: var(--tree-green);
                }
            }
        }

        .leaderboard-register-cta {
            min-height: auto;
            max-height: 120px;
        }

        .filter-blur {
            filter: blur(3px);
        }

        .progress-indicators {
            margin: 1rem auto;

            @media screen and (max-width: 375px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .step-icon {
                height: 25px;
                width: 25px;
                font-size: 15px;
                line-height: 25px;
            }

            .indicator-line {
                height: 2px;
            }
        }

        .my-stats {
            .overall-weekly-score-row {
                display: flex;
                justify-content: center;
                gap: 0.5rem;
            }

            .current-streak-row {
                .title-text {
                    font-size: 20px;
                }

                .days-text {
                    font-size: 28px;
                }
            }

            .title-text {
                font-family: "Noto Sans";
                opacity: 0.6;
                font-weight: 300;
                font-size: 16px;
            }

            .trophy-row {
                .award-text {
                    position: absolute;
                    transform: translate(1.1rem, 0.35rem) !important;
                    color: whitesmoke;
                    font-weight: bolder;
                }

                svg {
                    font-size: 3rem;

                    &.weekly_regional_first_place_trophy,
                    &.daily_regional_first_place_medal {
                        color: #c9b037;
                    }

                    &.weekly_regional_second_place_trophy,
                    &.daily_regional_second_place_medal {
                        color: #d7d7d7;
                    }

                    &.weekly_regional_third_place_trophy,
                    &.daily_regional_third_place_medal {
                        color: #6a3805;
                    }
                }

                .custom-award-icon {
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: $default-icon-size;
                    height: calc($default-icon-size + 0.25rem);

                    &.trophy {
                        background-size: $trophy-icon-size;
                        height: $trophy-icon-size;

                        &.placement-1 {
                            background-size: 3rem;
                        }

                        &.placement-2 {
                            background-size: 3rem;
                        }

                        &.placement-3 {
                            background-size: 3rem;
                        }
                    }
                }

                .counter-badge {
                    transform: translate(-1rem, 2.25rem) !important;
                    font-size: 0.8rem;
                    color: var(--white);
                    font-weight: bold;
                    background-color: var(--purple-heart);
                    width: $award-counter-badge-dimension;
                    height: $award-counter-badge-dimension;
                    line-height: 0.7rem;

                    &.custom-counter {
                        transform: translate(0.25rem, -0.75rem) !important;

                        &.medal {
                            transform: translate(0.25rem, -0.65rem) !important;
                        }
                    }
                }
            }
        }

        .guest-register-helper-text {
            color: var(--onyx);
            text-align: center;
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;

            span {
                text-decoration: underline;
                cursor: pointer;
                color: var(--purple-heart);

                &:hover {
                    text-decoration: underline;
                    color: var(--meteorite);
                }
            }
        }
    }
}
