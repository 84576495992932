@import "../../../../node_modules/bootstrap/scss/_mixins";

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  // background: rgba(0, 0, 0, 0.834);
  background-color: white;
  z-index: 1;
  margin-top: -10vh;

  .loading-text {
    @include rfs(3.5rem, font-size);
  }

  &.inline {
    position: relative;
    width: inherit;
    height: inherit;
    margin-top: inherit;
    background-color: inherit;
  }
}