$rfs-base-value: 1rem;

@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins";

.property-container {
    .property-map-container {
        max-width: 600px;
        margin: 0 auto;
    }
    .property-image-swiper {
        position: relative;

        .swiper-wrapper {
            max-width: 600px;
            max-height: 100%;

            .swiper-slide {
                position: relative;
                overflow: hidden;
                margin: 0 auto;

                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;

                @media screen and (max-width: 375px) {
                    background-size: cover;
                }

                &.swiper-slide-active {
                    opacity: 1;
                }

                &:not(.swiper-slide-active) {
                    opacity: 0 !important;
                }
            }
        }

        .swiper-pagination {
            bottom: 0.5rem;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;

            @media screen and (max-width: 375px) {
                justify-content: flex-end;
                padding-right: 0.5rem;
            }

            .pagination-fraction-wrapper {
                background-color: #000000;
                color: var(--white);
                opacity: 0.75;
                font-weight: bold;
            }
        }

        .swiper-button-disabled {
            display: none;
        }

        .swiper-button-prev,
        .swiper-button-next {
            color: var(--midnight);
        }

        .swiper-button-prev {
            left: calc(50vw - 32%);

            @include media-breakpoint-up(xl) {
                left: calc(50vw - 25%);
            }
        }

        .swiper-button-next {
            right: calc(50vw - 32%);

            @include media-breakpoint-up(xl) {
                right: calc(50vw - 25%);
            }
        }

        @include media-breakpoint-down(sm) {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
    }

    .property-details {
        max-width: 600px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;

        .property-details-key {
            #see-similar-properties-btn {
                span {
                    width: 100%;
                    max-width: 262px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        #address {
            color: #000;
            text-align: center;
            font-family: "Merriweather";
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 133.333% */
            letter-spacing: 0.5px;
        }

        .main-info-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-evenly;
            align-items: baseline;

            div {
                font-size: 16px;

                span {
                    color: #000;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }

                p {
                    display: inline-flex;
                    opacity: 0.6;
                }

                &.main-info-column {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }

        &:not(.property-name) {
            padding-top: 15px;
        }

        .show-more-btn {
            text-decoration: none;
            display: block;
            color: #000;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20.5px; /* 128.125% */
            letter-spacing: 0.5px;
            background-color: transparent;
            padding: 0;

            &:hover {
                cursor: pointer;
            }
        }

        .subtitle {
            color: #000;
            font-family: "Merriweather";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.5px;
        }

        .truncated-public-remarks-container > span > span,
        .public-remarks,
        .sales-info-disclaimer {
            color: #757575;
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.5px; /* 128.125% */
            letter-spacing: 0.5px;
        }

        .toggle-sales-info {
            background-color: transparent;
            border-radius: 500px;
            color: #3b166a;
            text-align: center;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }


    .guess-input-container-shadow {
        box-shadow: 2px -23px 19px -20px rgba(0,0,0,0.05);
    }

    .guess-input-sticky {
        bottom: 0;
        position: sticky;
    }

    .guess-input-container {
        max-width: 600px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        font-size: 14px;

        .score-to-beat {
            color: var(--onyx);
            font-size: 14px;
            font-style: normal;
            line-height: 40px;
            letter-spacing: 0.5px;
            max-width: 45%;
        
            .score {
                font-size: 24px;
            }
        }

        .input-btn-container {
            flex-wrap: wrap;
            position: relative;
            padding-top: 0.7rem;

            .input{
                width: 100%;
            }
            .btn{
                width: 100%;
            }

            @media screen and (min-width: 350px) {
                flex-wrap: nowrap;

                .input{
                    width: 60%;
                }
                .btn{
                    width: 40%;
                }
                .done-btn {
                    width: 20%
                }
           }
        }

        & > div {
            padding-right: 0;
        }

        .progress-indicator-container {
            width: 100%;
            max-width: 200px;

            &.scrollable {
                width: 50%;
                max-width: 235px;
            }
        }

        #done-btn,
        #guess-price,
        #submit-guess,
        #next-property-main {
            width: 100%;
            height: 56px;
            border-radius: 12px;

            &:disabled {
                opacity: 65%;
                pointer-events: none;
            }
        }

        #submit-guess,
        #next-property-main {
            font-size: 16px;
        }

        div:has(.next-property-button) {
            padding-left: 0;
        }

        #next-property-main {
            padding: 0.5rem 1rem;
        }
    }

    .details-item {
        gap: 30px;
        margin-bottom: 1rem;
        color: var(--onyx);
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.5px;

        p {
            width: 60%;
            margin: 0;
        }

        span {
            width: 40%;
        }
    }
}
