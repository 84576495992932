$rfs-base-value: 1rem;
$container-padding-x: 1rem;

@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins";

.startup-screen {
    max-width: 600px;
    margin: 0 auto;
    .container {

        .direction-text {
            font-size: 14;
            font-family: "Noto Sans";
        }

        .button-group {
            padding: 1.5rem 1.5rem 1rem 1.5rem;
            display: none;
            flex-direction: column;
            gap: 0.7rem;

            @media screen and (min-width: 576px) {
                display: flex;
            }
        }

        .button-group-drawer {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            background: var(--white);
            z-index: 97;
            padding: 1.5rem 1.5rem 1rem 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 0.7rem;
            filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.1));

            @media screen and (min-width: 576px) {
                display: none;
            }
        }

        .button-drawer-padding {
            display: block;

            @media screen and (min-width: 576px) {
                display: none;
            }
        }

        .welcome-section {
            .welcome-img {
                margin: 2rem 0;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                width: 100%;
                max-width: 250px;
                margin: auto;
                margin-bottom: 1rem;
            }

            .welcome-subtitle {
                p {
                    font-size: 18px;
                    font-weight: 300;
                }
            }

            .welcome-btn {
                padding: 0 1.5rem;
            }
        }

        .user-stats-section {
            font-size: 14px;

            h5 {
                font-size: 25px;
            }

            .user-stats-col {
                background-color: #FAFAFA;
                border-top: 2px solid #f4f4f4;
                border-bottom: 2px solid #f4f4f4;
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                flex-wrap: wrap;
                padding: 0.7rem 1.7rem;
                @media screen and (min-width: 576px) {
                    justify-content: flex-start;
                }
            }
        }

        .mh-120 {
            min-height: 120px;
        }
        .startup-stats-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media screen and (min-width: 375px) {
            padding: 0 $container-padding-x 0 $container-padding-x;
        }

        padding: 0 1.75rem 0 1rem;

        .title {
            font-weight: bold;
            font-size: 24px;
            font-family: "Merriweather";
            line-height: 1.4;
            margin: 0;
        }

        .left-col {
            text-align: left;
        }

        .right-col {
            text-align: right;
        }

        .day-progress-indicator-row {
            padding-top: 2.5vh;

            .step-icon {
                font-size: 12px;
            }
        }

        .button-row {
            display: flex;
            justify-content: center;

            &.start-button-row {
                padding-top: 2.5vh;
                padding-bottom: 5vh;
            }

            &.switch-button-row {
                padding-top: 5vh;
            }

            button {
                width: 100%;
                border-color: var(--midnight);
                font-weight: bold;

                &.start-button {
                    background-color: var(--tree-green);
                }

                &.switch-button {
                    background-color: var(--gold);
                    color: var(--midnight);
                    column-gap: 0.25rem;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .sticky {
        z-index: 2;
    }
}
