.choose-game-container {
    padding: 1.5rem;
    max-width: 600px;
    margin: 0 auto;

    .welcome-text {
        font-size: 24px;
    }

    .choose-your-game-text {
        font-family: "Noto Sans";
        color: #666666;
        font-size: 18px;
        margin-bottom: 2rem;
    }

    .info-container {
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }

        &.disable-text {
            color: #666666;
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        }

        .choose-game-image-container {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .choose-game-info-text {
            font-family: "Noto Sans";
            font-size: 14px;
            width: 70%;

            p {
                margin-bottom: 2px;
            }
        }
    }
}
