.soldout-modal {
    .close-icon-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .header-icon {
            flex: 1; /* Take remaining space */
            text-align: center;
            font-size: 21px;
            font-weight: bold;
            font-family: "Merriweather";
        }

        .close {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .modal-content {
        width: 100%;
        max-width: 320px;
        overflow-x: auto;

        .modal-text-container {
            padding: 0px 40px;
        }

        .soldout-icon {
            display: flex;
            justify-content: center;
        }

        h5 {
            line-height: 35px;
        }

        p {
            color: #666666;
            margin-top: 15px;
            font-size: 14px !important;

            .to-faq-text-link {
                color: #1597bb;
                text-decoration: none;
            }
        }
    }
    .modal-footer {
        .send-challenge {
            font-size: 14px !important;
            margin-top: 10px;
        }
    }
}
