.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 2px 40px;
    font-size: 16px;
    font-family: "Noto Sans";
    width: 22%;
}

.undo-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 5px 1px;
    width: 10%;
}
