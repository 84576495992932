.guess-scores-container {
    display: flex;
    align-items: center;
    width: 60%;

    &.justify-center {
        justify-content: center;
    }

    .guess-icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 1;
        font-weight: 700;

        .guess-score {
            position: absolute;
            top: 110%;
            font-size: 20px;
            font-weight: 600;
            font-family: "Noto Sans";
            color: var(--onyx);
        }

        .guess-number {
            border-radius: 50%;
            background: var(--purple-heart);
            text-align: center;
            color: var(--white);
            position: relative;
            height: 28px;
            width: 28px;
            font-size: 12px;
            line-height: 20px;

            &.inactive {
                background: #c2c2c2;
            }
        }
    }

    .indicator-line {
        width: 100%;
        height: 3px;
        background: #bbbbbb;
        flex: 1;
        min-width: 27px;

        &.corner-line {
            position: absolute;
            min-width: 13px;
            width: 0;
            top: 41%;

            &.start {
                left: 15%;

                &.short-line {
                    position: inherit;
                    max-width: 13px;
                    margin-left: -8%;
                }
            }

            &.end {
                right: 15%;
            }
        }
    }
}
