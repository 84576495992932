@import "../../../../node_modules/bootstrap/scss/_mixins";

$social-media-icon-size: 35px;

// Header Styles
.side-menu {
    z-index: 9;
    position: fixed;
    top: 9svh;
    left: -300px; // Start hidden to the left
    width: 70svw;
    max-width: 250px;
    height: 100%;
    background-color: var(--bs-body-bg);
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease-in-out; // Add a transition effect

    &.open {
        left: 0; // Slide from the left when open
    }

    .menu-header {
        height: 10svh;
        background-color: #ccc;
    }

    .close-icon {
        cursor: pointer;
        font-size: 20px;
    }

    .follow {
        margin-bottom:5px;
    }

    .menu-items {
        height: 76svh;
        list-style: none;
        padding: 0;

        li {
            padding: 15px 20px;
            border: none;

            &:hover {
                cursor: pointer;
            }

            &:last-child {
                border-bottom: none;
            }

            svg {
                margin-right: 1rem;
            }

            &.blur {
                opacity: 0.1;
            }
        }
    }

    .social-media-links {
        height: 10svh;

        center {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            grid-column-gap: 1rem;
        }

        .social-icon {
            width: $social-media-icon-size !important;
            height: $social-media-icon-size !important;
        }
    }
}
