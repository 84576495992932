.bonus-reminder-modal {
    &__content {
        width: 85svw;
        max-width: 350px;
        border: 1px solid var(onyx);
        position: relative;

        .close-btn {
            position: absolute;
            right: 2%;
            top: 2%;
            z-index: 99;
            &:hover {
                cursor: pointer;
            }
        }

        @media screen and (max-width: 374px) {
            h5 {
                font-size: 16px !important;
            }

            p,
            button {
                font-size: 12px !important;
            }
        }
    }

    &__body {
        padding: 1.25rem 1.5rem 0.75rem;
        color: var(onyx);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;

        &--title {
            line-height: 35px;
            font-weight: 700;
            font-size: 21px;
        }

        &--text {
            color: #666666;
            line-height: 21.79px;
            font-size: 16px;
            font-weight: 400;
        }

        &--icon {
            max-width: 103px;
        }
    }

    &__footer {
        .app-button,
        .remind-later-button {
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.5px;
            width: 100%;
            max-width: 230px;
            line-height: 21.79px;
            text-decoration-line: none;
            text-align: center;
        }

        .app-button {
            color: var(--onyx);
        }

        .remind-later-button {
            color: var(--purple-heart);
        }

        .footer-text {
            font-size: 9px;
            line-height: 12.26px;
            letter-spacing: 0.5px;
            text-align: center;
            font-weight: 500;
            color: var(--purple-heart);
        }
    }
}
