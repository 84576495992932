.awards-modal {

    .awards-modal-content {
        max-width: 350px;
        border: 1px solid var(onyx);
        position: relative;
        
        .close-btn {
            position: absolute;
            right: 2%;
            top: 2%;
            z-index: 99;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .awards-modal-body {
        padding: 2rem 2rem; 
        color: var(onyx);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        h2 {
            font-weight: bold;
            text-align: center;
            margin: 0;
            font-size: 21px;
        }
    
        .award-container {
            padding: 1.5rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            text-align: center;
            max-width: 280px;
            margin: auto;
            flex-direction: column;
          
            .award-icon {
                position: relative;
                img {
                    height: 90px;
                    width: auto;
                }
            }

            h4 {
                margin: 0;
                font-weight: bold;
                font-size: 36px;
            }
            h5 {
                font-size: 21px;
            }
        }

        button {
            font-weight: 300;
            letter-spacing: 1px;
            width: 100%;
            max-width: 230px;
        }
    }
}