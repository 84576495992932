.user-login {
    color: white;
    font-weight: 400;
    line-height: 0;
    margin-bottom: 0;
    font-family: 'Noto Sans';
    font-size: 15px;
    letter-spacing: 1px;
    position: relative;
    
    &:hover {
        cursor: pointer;
    }

    .user-profile {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &.default-avatar {
            background-color: var(--deep-saffron);
        }
        
        font-size: 14px;
        color: black;
        
        @media screen and (min-width: 576px) {
            max-width: 40px; 
        }
    }
}