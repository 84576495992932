#share-button {
    &.under-finished-modal {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background-color: var(--tree-green) !important;
        color: var(--white) !important;
        column-gap: 0.5rem !important;

        svg {
            color: var(--midnight) !important;
        }
    }
}
