.bonus-property-modal {
    .modal-content {
        width: 100%;
        max-width: 320px;
        .bonus-guess-icon {
            display: flex;
            justify-content: center;
            img {
                width: 86px;
                height: auto;
            }
        }

        h5 {
            line-height: 35px;
        }

        p {
            color: #666666;
        }
    }
}
