.finished_first_play_modal_container {
   .modal_content {
    width: 100%;
    max-width: 320px;
   }

   .close_btn {
        position: absolute;
        right: 5px;
        top: 5px;

        z-index: 10;

        &:hover {
            cursor: pointer;
        }

   }

    .modal_body {
        h5 {
            font-size: 21px;
            margin: 0;
            padding: 0rem 0.5rem;
        }

        .reminder {
            p {
                text-align: center;
                font-size: 14px;
                margin: 0;
                color: var(--gunmetal);
            }
        }

        .icon_container {
            display: flex;
            justify-content: center;
            margin: 1rem 0;
        }

        .scores_row {
            display: flex; 
            width: 100%;
            flex-wrap: wrap;
            gap: 10px;
            .scores_col {
                flex-grow: 1;

                .col_title {
                    margin-bottom: 0.2rem;
                    font-size: 15px;
                    color: var(--gunmetal);
                }

                ul {
                    padding: 0 0 0 0;
                    margin: 0 0 0.5rem 0;
                    list-style: none;
                    text-align: center;

                    li {
                        font-weight: 700;
                        font-size: 24px;
                        margin-bottom: 0.5rem;
                    }

                }

                .total {
                    text-align: center;
                    font-size: 28px;
                    font-weight: 700;
                    color: var(--purple-heart);
                }

            }
        }

        .share_button_container {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            justify-content: center;

            button {
                width: 70%;
              
            }
        }
    }
}