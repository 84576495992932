@import "../../../../node_modules/bootstrap/scss/_mixins";

// Header Styles
header {
    display: flex;
    justify-content: space-between;
    background-color: var(--meteorite);
    padding: 10px 0 10px 0;
    width: 100%;
    position: fixed;
    z-index: 99;
    .header-container {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;

        .title {
            @include rfs(1.5rem, font-size);
            color: white;
            font-weight: bold;
            line-height: 0;
            margin-bottom: 0;
            font-family: "Merriweather";
            font-size: 21px;
            letter-spacing: 1px;
            position: relative;

            @media screen and (min-width: 576px) {
                font-size: x-large;
            }

            .logo-link {
                display: flex;
                align-items: center;
                gap: 0.3rem;

                .priceme-logo {
                    width: 100%;
                    height: auto;
                    max-width: 35px;

                    @media screen and (min-width: 576px) {
                        max-width: 40px;
                    }
                }
            }
        }

        #menu-icon {
            @include rfs(2rem, font-size);
            margin-right: 0px;
            /* adjust this value as needed */
            color: white;
            padding-bottom: 3px;

            &:hover {
                cursor: pointer;
            }
        }

        .list-icon {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .header-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        font-weight: 700;
    }
}
