.progress-bar-container {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    margin-top: 20px;
    overflow: hidden;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .progress-bar {
        height: 100%;
        background-color: var(--meteorite);
        transition: width 0.1s linear;
    }
}
