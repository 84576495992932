.practice-property-modal {
    .modal-content {
        width: 100%;
        max-width: 320px;
        overflow: auto;
        text-align: center;
        max-height: 80vh;
    }

    /* WebKit-based browsers */
    .modal-content::-webkit-scrollbar {
        width: 12px;
    }

    .modal-content::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .modal-content::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 3px solid #f1f1f1;
    }

    .modal-content::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    .practice-modal-content {
        .practice-guess-icon {
            display: flex;
            justify-content: center;
            margin: 1.5rem 0;
        }

        h5 {
            line-height: 35px;
        }

        p {
            color: #666666;
        }

        .practice-modal-header-text {
            font-weight: 700;
            font-size: 30px;
            padding-top: 4.5rem;
        }

        .practice-points {
            font-size: 40px;
            font-weight: 600;
            color: #6c26c4;
        }

        .regiser-text-secondary-header {
            font-family: Merriweather;
            font-weight: 700;
            font-size: 30px;
        }

        .register-text {
            font-size: 20px;
            font-weight: 400;
            padding: 0px 2rem;
        }

        /* desktop view */
        @media (min-width: 768px) {
            .practice-modal-header-text {
                font-size: 1.5rem;
            }

            .practice-points {
                font-size: 25px;
            }

            .regiser-text-secondary-header {
                font-size: 1.25rem;
            }

            .register-text {
                font-size: 1rem;
            }
        }
    }
    .modal-footer {
        .close-btn {
            font-size: 24px;
            font-weight: 400;
            margin-top: 20px;
            color: #6c26c4;
            width: 100%;
        }

        .register-button {
            height: 75px;
            font-size: 24px;
            width: 250px;
        }
    }
}
