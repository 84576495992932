@import "custom";

/** Google Font - Merriweather */
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

/** Google Font - Noto Sans */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/** Fonts - Montserrat */
@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/montserrat/Montserrat-Regular.otf");
    font-weight: normal;
}

/** Fonts - Sinkin */
@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-300Light.otf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-300LightItalic.otf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-400Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-400Italic.otf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-500Medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-600SemiBold.otf");
    font-weight: 600;
}

@font-face {
    font-family: "Sinkin";
    src: url("./assets/fonts/sinkinsans/SinkinSans-700Bold.otf");
    font-weight: 700;
}

:root {
    /* Original Palette */
    --light-blue: #add8e6;
    --midnight: #27273b;
    --emperor-blue: #343462;
    --dark-grey: #707070;
    --light-grey: #f1f1f1;
    --mint: #c1e6d9;
    --tree-green: #63c19f;
    --button-hover: #63c19f;
    --white: #ffffff;
    --gold: #fbc036;
    --link-blue: #030dff;

    /* Reskin Palette */
    --meteorite: #3b166a;
    --purple-heart: #6c26c4;
    --gunmetal: #323137;
    --onyx: #100f13;
    --pacific-blue: #13a2be;
    --light-mustard: #ffc773;
    --deep-saffron: #f69929;
    --ghost-white: #fafafa;
    --apricot-sherbet: #fbb663;
    --nobel-gray: #9e9e9e;
    --grey: #d9d9d9;
}

html {
    font-size: 16px;
    overscroll-behavior: none;
    /* or whatever base size you want */
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: var(--onyx);
}

a {
    color: inherit !important;
}

#root {
    height: 100%;
}

.app-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .app-content {
        flex-grow: 1;
        padding-top: 9svh;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes explode {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.score-explode {
    animation: explode 1s ease-in-out;
}

.pulse {
    animation: pulse 1s linear 5;
}

.hidden {
    visibility: hidden;
}

.details-hidden {
    display: none;
}

#final-score-label {
    font-size: 2rem;
    /* adjust this as needed */
    font-style: italic;
    color: var(--emperor-blue);
}

#final-score-display {
    font-size: 2rem;
    /* adjust this as needed */
    font-style: italic;
    color: var(--emperor-blue);
}

body {
    font-family: "Noto Sans", "Lato", Arial, sans-serif;
    background-color: --white;
    margin: 0;
    padding: 0;
    color: var(--onyx);

    &.modal-open {
        padding-right: 0 !important;
    }
}

h1 {
    font-size: 6vw;
    /* adjust this as needed */
    color: var(--white);
    padding: 10px 10px 10px 5px;
}

h2 {
    font-size: 1.5rem;
}

button {
    color: white;
    background-color: var(--midnight);
    border-radius: 5px;
    border: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#prev-btn,
#next-btn {
    position: absolute;
    top: 50%;
    font-size: 2rem;
    transform: translateY(-50%);
    background-color: var(--midnight);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease;
    opacity: 0.9;
}

#prev-btn {
    left: 10px;
}

#next-btn {
    right: 10px;
}

#prev-btn:hover,
#next-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

#address {
    font-size: 1rem;
}

.property-details {
    padding: 20px 20px 20px 20px;
    margin: 0px 0px 0px 0px;
    background-color: var(--white);

    &:not(.property-name) {
        padding-top: 5px;
    }
}

.property-details p {
    margin: 5px 0;
}

.start-button-container,
.guess-input-container {
    padding: 10px 10px 20px 20px;
    margin: 0px 0px 0px 0px;
    background-color: var(--white);
}

#next-property-main {
    font-size: 1.3rem;
    /* adjust this as needed */
    margin-right: 0rem;
    background-color: orange;
    color: var(--midnight);
}

#next-property-modal {
    font-size: 1rem;
    /* adjust this as needed */
    @include rfs(0.5rem, padding-top);
    @include rfs(0.5rem, padding-bottom);
    @include rfs(1.5rem, padding-left);
    @include rfs(1.5rem, padding-right);
}

.property-details-key h2 {
    text-align: center;
}

#map-link {
    display: block;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
}

#results-popup,
#finished-popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90vw;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

#finished-h1 {
    color: var(--emperor-blue);
}

/*Walkthrough slides css*/
.popup-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    position: relative;
}

.slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.slide-content {
    margin-bottom: 20px;
}

.slide .prev-btn,
.slide .next-btn,
#start-game-btn {
    margin: 10px;
}

#slide-indicator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* Centers the element */
    display: flex;
    justify-content: center;
    width: 100%;
}

.indicator {
    height: 10px;
    width: 10px;
    background-color: gray;
    border-radius: 50%;
    margin: 5px;
}

.indicator.active {
    background-color: black;
}

.hidden {
    display: none;
}

.button-container {
    display: flex;
    /* This makes the buttons inside the container appear in a row */
    justify-content: space-between;
    /* This spreads the buttons apart */
}

.button-container button {
    margin: 0 5px;
    /* This adds a buffer of 5px on each side of each button, totaling 10px of space between the buttons */
}

#email-btn {
    font-size: 2rem;
    padding-left: 20px;
    padding-right: 20px;
}

// Remove active state styles for buttons
@media (max-width: 767px) {
    .btn:active,
    .btn:focus,
    .btn:active:focus {
        outline: none;
        box-shadow: none;
    }
}

// Remove the number wheel from number inputs
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Header Styles
header {
    height: 9svh;
    display: flex;
    justify-content: space-between;
    background-color: --var(--tree-green);
    padding: 10px 0 10px 0;

    .header-left {
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 15px;

        .title {
            @include rfs(1.5rem, font-size);
            color: white;
            padding: 10px 10px 10px 5px;
            font-weight: bold;
            line-height: 0;
            margin-bottom: 0;
        }

        #menu-icon {
            @include rfs(2rem, font-size);
            margin-right: 5px;
            /* adjust this value as needed */
            color: var(--midnight);
            padding-bottom: 3px;
        }
    }

    .header-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        font-weight: 700;

        #score-display {
            @include rfs(1.5rem, font-size);
            color: var(--light-grey);
            justify-content: space-between;
            text-align: right;
            border-radius: 20px;
            /* Adjust this value to achieve the rounded corners effect you want */
            height: 3rem;
        }

        #score-label {
            @include rfs(1.5rem, font-size);
            color: var(--light-grey);
        }
    }
}

.blur {
    color: transparent !important;
    text-shadow: 0 0 5px #000;

    &:hover {
        cursor: pointer;
    }
}

input {
    &:disabled {
        background-color: lightgray;
        cursor: not-allowed;
        pointer-events: none;
    }
}

:disabled {
    &:hover * {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.custom-toast {
    text-align: center;
    width: 312px;
    height: 69px;
    flex-shrink: 0;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
    filter: drop-shadow(0px 0px 9.9px rgba(0, 0, 0, 0.25));

    &.custom-success-toast {
        border: 4px solid #98ffa1;
    }

    &.custom-error-toast {
        border: 4px solid #ff9999;
    }
}

.link-text {
    text-decoration: underline;
    color: blue;
}

// Version Rocket customizations
#version-rocket {
    .version-area {
        width: 90svw !important;
        margin: 0 auto;
        padding-bottom: 0.5rem;
        max-width: 300px;

        @media only screen and (max-width: 576px) {
            left: 0;
            right: 0 !important;
        }

        img.version-img {
            display: none;
        }

        .version-content {
            padding-top: 9px;
            border-top: 10px solid var(--tree-green);

            div {
                color: var(--midnight);
            }

            .refresh-button {
                position: relative;
                width: 5rem;
                float: right;
                animation: none;
                font-size: 12px;
                background-color: var(--gold);
                border: 1px solid var(--midnight);
                padding: 2px 0;
            }

            .cancel-button {
                position: relative;
                width: 5rem;
                float: left;
                padding: 5px 0;
                margin-top: 12px;
            }
        }
    }
}
// End of Version Rocket customizations

.has-tooltip {
    text-decoration: underline #000 dotted;
    text-underline-offset: 3px;
}

.react-tooltip {
    background: var(--midnight) !important;
    max-width: 95svw;
    z-index: 1;
}

.app-text-title {
    font-family: "Merriweather";
    font-weight: bold;
}

.app-text-link {
    color: var(--purple-heart);
    font-weight: bold;
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    align-self: flex-start;
}

.app-text-muted {
    color: var(--gunmetal);
    opacity: 60%;
}

.subtitle {
    color: var(--onyx);
    font-family: Merriweather;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 190.476% */
    letter-spacing: 0.5px;
}
