.total-score {
    color: var(--onyx);
    font-size: 14px;
    font-style: normal;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.5px;
    max-width: 45%;

    .score {
        font-size: 24px;
    }
}
