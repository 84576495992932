.google-form-control {
    position: relative;
    padding: 0 !important;

    /* Styles for the clear button */
    .close.btn-close.rbt-close {
        border: 1px solid #c2c2c2;
        padding: 12%;
        border-radius: 50%;
        font-size: 0.5rem;
    }

    .error-message {
        color: red !important;
    }

    .clear-guess-btn {
        right: 0.5rem;
        color: var(--grey);
        background-color: transparent;

        &:hover {
            background: transparent;
        }
    }

    input {
        &.select-location-input {
            padding-right: 2rem;
        }
    }

    .area-suggestions-dropdown {
        position: absolute;
        background-color: white;
        border: 1px solid rgba(52, 52, 98, 0.1);
        top: calc(100% + 0.1rem);
        border-radius: 0.5rem;
        box-shadow:
            0 4px 6px -2px #0000001a,
            0 2px 4px -2px #0000001a;
        width: 100%;
        z-index: 9;
        ul {
            list-style: none;
            margin: 0;
            padding: 0.3rem;
            max-height: 200px;
            overflow: auto;
            li {
                p {
                    text-align: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin: 0;
                }
                padding: 0.3rem 0.5rem;
                border-radius: 0.4rem;
                font-size: small;
                width: 100%;
                @media only screen and (min-width: 576px) {
                    font-size: medium;
                }
                &:hover {
                    background-color: var(--tree-green);
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}
