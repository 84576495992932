@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins";

.modal {
    #startup-modal {
        .modal-body {
            padding-left: 1.75rem;
            padding-right: 1.75rem;
            a {
                &:hover {
                    cursor: pointer;
                }
            }

            .register-cta {
                font-weight: 700;
                color: var(--emperor-blue);
            }

            .dont-show-again {
                justify-content: center;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                input,
                label {
                    width: auto;
                    &:hover {
                        cursor: pointer;
                    }
                }

                label {
                    padding-left: 0.5rem;
                }
            }

            .swiper-slide {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    .modal-dialog {
        @media screen and (max-width: 575.98px) {
            &.modal-fullscreen-sm-down {
                height: 91svh !important;
                min-height: 91svh !important;
                top: 9svh !important;

                .app-text-title {
                    font-size: 28px;

                    .challenger-name {
                        font-style: italic;
                    }
                }

                .slide-img-wrapper {
                    margin-top: 3rem;
                }

                .modal-content {
                    max-width: 100% !important;

                    .modal-body {
                        overflow-y: hidden;
                    }
                }
            }
        }

        justify-content: center !important;

        .modal-body {
            .modal-title {
                @include rfs(2rem, font-size);
                color: var(--emperor-blue);
                font-weight: bold;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 1rem;
            }

            h6 {
                @include rfs(0.65em, font-size);
                font-style: italic;
                text-align: center;
            }

            p {
                @include rfs(1rem, font-size);
                text-align: center;
            }
        }
    }

    #results-modal {
        .modal-content {
            @include rfs(70%, width);

            p,
            h4 {
                text-align: center;
            }
        }
    }

    .modal-footer {
        border-top: none;
        justify-content: center;

        .swiper-custom-pagination {
            position: static;
        }

        .swiper-button-disabled {
            display: none;
        }

        .startup-modal-btn {
            background-color: var(--tree-green);
            border: 1px solid var(--midnight);
            font-weight: bold;
            font-size: large;
            padding: 0.5rem 2rem;
            color: var(--white);
        }
    }

    #finished-modal {
        .mh-120 {
            min-height: 120px;
        }

        .modal-content {
            border: 1px solid var(--midnight);
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: 85%;
            }
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;

            .modal-title {
                @include rfs(2.5rem, font-size);
                line-height: 1.2;
                padding: 0;
                margin: 1rem 0;
                @media only screen and (min-width: 576px) {
                    @include rfs(2rem, font-size);
                }
            }

            h5 {
                @include rfs(1rem, font-size);
                text-align: start;
            }

            p {
                color: var(--midnight);
            }

            .finished-score,
            .finished-rank {
                text-align: right;
            }
        }
        .leaderboard-link-btn,
        #share-button {
            margin: 0 auto;
            border: 1px solid var(--midnight);
            font-weight: bold;
            background-color: var(--gold);
            color: var(--midnight);
            padding: 0.6rem 1rem;
            margin-bottom: 1rem;
        }
    }

    #game-lock-modal {
        .game-lock-title {
            color: var(--emperor-blue);
        }

        .leaderboard-link-btn-desktop {
            margin: 0 auto;
            font-weight: bold;
            background-color: var(--gold);
            color: var(--midnight);
            padding: 0.6rem 1rem;
            border-radius: 5px;
            text-decoration: none;
            border: 1px solid var(--midnight);
        }
    }

    #dialogue-modal {
        .close-btn {
            all: unset;
            position: absolute;
            right: 3.5%;
            top: 4%;
        }
        .copy-link-container {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0 auto;
            max-width: 230px;

            button {
                height: 100%;
                aspect-ratio: 1;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            input {
                flex-grow: 1;
                border-radius: 4px;
                border: 1px solid rgba(39, 39, 59, 0.6);
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: 0.3rem 0.8rem;
                font-size: small;
                background-color: rgba(39, 39, 59, 0.05);
            }
        }
    }
}
