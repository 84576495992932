.first-property-price-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 43%;

    .first-property-price {
        position: relative;
        z-index: 10;
        background-color: #aad880;
        border-radius: 500px;
        font-size: 20px;
        padding: 2px 30px;
    }
}

.versus-icon-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;

    .versus-icon {
        color: white;
        background-color: #6c26c4;
        font-size: 20px;
        border-radius: 50%;
        padding: 6px 9px;
        font-family: "Noto Sans";
        position: relative;
        z-index: 10;
        font-weight: bold;
    }
}

/* For desktop screens */
@media (min-width: 1024px) {
    .first-property-price-container {
        top: 60%;
    }
}
