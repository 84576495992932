
.select-location-modal {
    &.guest-modal {
        .close-icons {
            padding: 15px;

            .close {
                display: none;
            }
        }
    }

    .close-icons {
        float: right;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        .close {
            cursor: pointer;
        }
    }

    .modal-content {
        width: 100%;
        max-width: 320px;

        .modal-footer { 
            padding-bottom: 3rem;
        }

        .swiper-pagination {
            position: static;

            margin: 0.7rem 0;

            .swiper-pagination-bullet {
                height: 10px;
                width: 10px;
            }
            .swiper-pagination-bullet-active {
                background: var(--deep-saffron);
            }
        }

        a {
            &:hover {
                cursor: pointer;
            }
        }

        .modal-body {
            p {
                font-family: Noto Sans;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.5px;
                text-align: center;
            }
        }
    }

    .select-location-modal-slide-group {

        h5 {
            font-size: 21px;
        }
        p {
            color: #666666;
        }

        .register-cta {
            color: var(--purple-heart);
            font-weight: bold;
            text-decoration: none;
        }

        .slide-img-wrapper {
            display: flex;
            justify-content: center;
            margin: 1.5rem 0;

            .select-location-modal-icon {
                width: 100%;
                height: auto;
            }

            .house-sold-icon {
                max-width: 78px;
            }
        }
    }
}
