$rfs-base-value: 1rem;

@import "../../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../../node_modules/bootstrap/scss/mixins";


.profile-backdrop {
    z-index: 9;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    display: none;

    @media screen and (min-width: 576px) {
        display: block;
    }

    &.open {
        opacity: 0.4;
    }
}

.profile-drawer {
    z-index: 98;
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 600px;
    background-color: rgba(255,255,255, .85);
    backdrop-filter: blur(8px);
    padding: 0;
    right: -100%;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease-in-out; // Add a transition effect
    top: 0;
    padding-top: 70px;
    overflow: auto;
    &.open {
        right: 0; // Slide from the left when open
    }

    .section-group{
        
        .section-label {
            font-size: 14px;
        }

        .logout {
            font-weight: 400;
            color: red;
            cursor: pointer;
        }

        font-size: 16px;
    }

    .border-b {
        border-bottom: 1px solid var(--grey);
    }


    .flex-grow-1 {
        flex-grow: 1;
    }
    
    .flex-grow-2 {
        flex-grow: 2;
    }
    
    .text-sm {
        font-size: small;
    }
    .grid-container {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 5px;
        font-size:13px;
        line-height: 1;
        align-items: center;
        @media only screen and (min-width: 576px) {
            font-size:18px;
        }
        .header{
            font-size: 15px;
            font-weight: bold;
            
        }
        .notif-type{
            text-align: center;
        }
      }
      
    .notif-label {
        width: 110px;
    }

    .grid-item {
        padding:5px;
        align-items: center;
        .dropdown-container {
            position: relative;
            display: inline-flex;
            width: 100%;
            max-width: 100px;
            @media only screen and (min-width: 576px) {
                min-width: 100px;
            }
            .dropdown-text {
                min-width: 80px;
            }
            .dropdown-input {
                min-width: 100px;
            }
            
            .dropdown {
                font-size: 16px;
            }
        }
    }

    .profile-container {
        max-width: 600px;
        padding-left: 1rem;
        padding-right: 1rem;
        .close-btn {
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            color: var(--midnight);
            &:hover {
                opacity: .8;
            }
        }
    }
    
    .profile {
        .profile-section-header {

            .profile-section-title {
                font-size: 24px;
            }
        }

        
        .profile-setting-container {
            display: flex;
            justify-content: space-between;
          
            .setting-input-group {
                text-align: right;
                p {
                    line-height: 1;
                    word-break: break-word;
                }
                a {
                    text-decoration: underline;
                    color: var(--link-blue);
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .profile-form {
                width: 100%;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                gap: 0.5rem;
                input {
                    width: 100%;
                    padding: .3rem .5rem;
                    border: 1px solid var(--midnight);
                    border-radius: 4px;
                }
                .btn-group {
                    display: flex;
                    gap: 5px;
                    height: 100%;
                    button {
                        width: 30px;
                        height: 30px;
                        padding: 0;
                        border: 1px solid var(--onyx);
                    }
                    .undo-btn {
                        background: transparent;
                        border: 1px solid var(--meteorite);
                        color: var(--meteorite);
                        
                        &:hover {
                            opacity: .9;
                        }
                    }
                    .save-btn {
                        background: var(--meteorite);
                        color: var(--white);
                        &:hover {
                            opacity: .9;
                        }
                    }
                }
            }
        }
    }
}