.faq-container {
    max-width: 600px;
    .faq-section-title {
        font-size: 21px;
    }

    .accordion {
        .card {
            border: 1px solid var(--grey);
            border-radius: .8rem;
            background-color: var(--white);
        }
        
        .accordion-body {
            padding: 1rem 0;
        }

        .accordion-collapse {
            border-top: 1px solid var(--grey);
            margin: 0 1.2rem;
        }
    
        .accordion-title-button {
            gap: 2rem;
            font-weight: 700;
            padding: 1rem 1.2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: .5rem;

            .toggle-icon {
                font-size: large;
                @media screen and (min-width: 576px) {
                    font-size: x-large;
                }
            }
            transition: all;
            transition-duration: .2s;
            &:hover {
                cursor: pointer;
            }
        }
    }
   
}