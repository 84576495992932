@mixin common-slide-image-wrapper {
    .slide-img-wrapper {
        display: flex;
        justify-content: center;

        .startup-modal-icon {
            width: 100%;
            height: auto;
        }

        .house-sold-icon {
            max-width: 166px;
            height: auto;
        }

        .challenged-icon,
        .price-tag-icon {
            max-width: 120px;
        }

        .unlock-icon {
            max-width: 100px;
        }

        .graph-icon {
            max-width: 117px;
        }
    }
}

@mixin common-modal-texts {
    p {
        color: #666666;
        text-align: center;
        font-size: 18px;
        line-height: normal;
        padding: 0 1rem;
    }

    .instruction-text {
        margin: 0;
        padding-left: 1.5rem;
    }

    .warmup-text {
        margin: 0;
        text-align: center;
        font-weight: 700;
        font-style: normal !important;
        font-size: 16px !important;
        padding: 0 !important;
    }
}

@mixin common-modal-body {
    .slide-group {
        h5 {
            margin: 0;
            font-size: 21px;
        }

        @include common-slide-image-wrapper;
        @include common-modal-texts;
    }
}

@mixin common-footer-options {
    &.startup {
        width: 100%;
    }

    &.onboarding {
        width: 201px;
    }
}

.startup-modal {
    /* Startup Modal - Registered (Onboarding) */
    padding: 0 11%;
    display: flex;
    justify-content: center;

    .modal-body {
        flex: 0 1 auto;

        .slide-group {
            min-height: 47vh;

            h5 {
                margin: 0;
                font-size: 30px;
            }

            @include common-slide-image-wrapper;

            @include common-modal-texts;

            p {
                padding: 0 1rem;
            }
        }

        .swiper-pagination {
            position: static;
            margin: 0.7rem 0;

            .swiper-pagination-bullet {
                height: 10px;
                width: 10px;
            }
            .swiper-pagination-bullet-active {
                background: var(--deep-saffron);
            }
        }

        a {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .modal-footer {
        .modal-button {
            height: 52px;

            @include common-footer-options;
        }
    }

    /* Desktop view - Registered */
    @media (min-width: 1024px) {
        padding: 0;

        .modal-content {
            max-width: 320px;

            .slide-group {
                .slide-section {
                    .header-text-container {
                        h5 {
                            font-size: 21px;
                        }
                    }
                }
            }
        }
    }

    /* Startup Modal - Guest */
    &.guest-modal {
        padding: 8% 16%;
        display: block;

        .modal-body {
            margin-bottom: 1.4rem;

            @include common-modal-body;
        }

        .modal-footer {
            .modal-button {
                height: 61px;

                @include common-footer-options;
            }
        }

        /* Desktop view - Guest */
        @media (min-width: 1024px) {
            padding: 0;

            .modal-content {
                padding: 8% 3% 3%;
                max-height: 80vh;
                max-width: 320px;
                overflow-y: auto;
                display: block;

                .modal-body {
                    margin-bottom: 0;
                    @include common-modal-body;
                }
            }

            /* WebKit-based browsers */
            .modal-content::-webkit-scrollbar {
                width: 12px; /* Width of the entire scrollbar */
            }

            .modal-content::-webkit-scrollbar-track {
                background: #f1f1f1; /* Color of the scrollbar track */
            }

            .modal-content::-webkit-scrollbar-thumb {
                background-color: #888; /* Color of the draggable part of the scrollbar */
                border-radius: 10px; /* Roundness of the scrollbar thumb */
                border: 3px solid #f1f1f1; /* Creates padding around the thumb */
            }

            .modal-content::-webkit-scrollbar-thumb:hover {
                background-color: #555; /* Color of the thumb when hovered */
            }
        }
    }
}
