.vibration-pulse {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: lighten(#6c26cc, 5%);
    z-index: -1;

    &.dot-animation {
        top: 40%;
        left: -4%;
        width: 9px;
        height: 9px;
        position: relative;
        background-color: var(--purple-heart);
    }

    &.inactive {
        background-color: lighten(#c2c2c2, 5%);
    }

    &.active {
        background-color: #3b166a;
        top: -15%;
        left: -15%;
        width: 130%;
        height: 130%;
    }
}
