.modal-container-finished {
    .modal-feedback {
        font-size: 21px;
        font-family: Merriweather;
        font-weight: bold;
    }

    .points {
        font-family: Noto Sans;
        font-weight: 600;
        margin-bottom: 20px;
    }
}
