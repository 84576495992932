@import "../../../../node_modules/bootstrap/scss/_functions";
@import "../../../../node_modules/bootstrap/scss/_variables";
@import "../../../../node_modules/bootstrap/scss/_mixins";

// Mixin for common swiper button styles
@mixin common-swiper-button-styles {
    display: inline !important;
    color: #ebebeb !important;
    &::after {
        font-size: 26px !important;
    }
}

.property-image-swiper {
    position: relative;
    height: 40vh;

    @media only screen and (min-width: 576px) {
        height: 380px;
    }
    .swiper-wrapper {
        max-width: 600px;
        max-height: 100%;

        .swiper-slide {
            position: relative;
            overflow: hidden;
            margin: 0 auto;

            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

            &.unclickable {
                pointer-events: none;
            }

            .reveal-btn {
                position: absolute;
                z-index: 9999;
                top: 41%;
                border-radius: 10px;
                padding: 2px 18px;
                pointer-events: auto;

                // Adjust for desktop (md and above)
                @include media-breakpoint-up(md) {
                    top: 47%;
                }
            }

            .image-container {
                height: 100%;
                width: 100%;
                max-width: 600px;
                overflow: "hidden";
                margin: 0 auto;
                display: "flex";
                justify-content: "center";
                align-items: "center";

                .property-images-blur {
                    filter: blur(20px);
                }

                img {
                    width: auto;
                    height: 100%; /* Maintain image aspect ratio */
                    display: block; /* Remove extra space below inline elements */
                    margin: 0 auto; /* Center the image within its parent */
                }
            }

            @media screen and (max-width: 375px) {
                background-size: cover;
            }

            &.swiper-slide-active {
                opacity: 1;
            }

            &:not(.swiper-slide-active) {
                opacity: 0 !important;
            }
        }
    }

    .swiper-pagination {
        bottom: 0.5rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        &:has(.pagination-fraction-wrapper.insufficient-image-text) {
            padding-right: 0;
            font-size: 0.75rem;
            justify-content: center;
        }

        @media screen and (max-width: 375px) {
            justify-content: flex-end;
            padding-right: 0.5rem;
        }

        .pagination-fraction-wrapper {
            background-color: #000000;
            color: var(--white);
            opacity: 0.75;
            font-weight: bold;
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-button-prev {
        left: calc(50vw - 32%);

        @include media-breakpoint-up(xl) {
            left: calc(50vw - 25%);
        }
    }

    .swiper-button-next {
        right: calc(50vw - 32%);

        @include media-breakpoint-up(xl) {
            right: calc(50vw - 25%);
        }
    }

    @include media-breakpoint-down(sm) {
        .swiper-button-prev {
            @include common-swiper-button-styles;
            left: 8% !important;
        }

        .swiper-button-next {
            @include common-swiper-button-styles;
            right: 4% !important;
        }
    }
}
